const images = {
    simpleLogo: require('./simpleLogo.png'),
    logo: require("./Logo.png"),
    skewImg1: require('./Custom Web and App.png'),
    skewImg2: require("./Digitalize Your Business.png"),
    skewImg3: require("./Innovative Solutions.png"),
    browser: require("./Logo SVG/web_11264265.svg"),//Icon
    appDev: require("./appDev.png"),//Icon
    devSol: require("./devSol.png"),//Icon
    uiux: require("./uiux_icon.png"),//Icon
    more: require("./more.png"),//More Icon on Services Page
    bulbs: require("./3bulbs.png"),
    tickSearch: require("./tickSearch.png"),
    coinsBag: require("./coinsBag.png"),//Icon
    skewImg4: require("./Ai Software.png"),
    skewImg5: require("./Vector 21.png"),
    serviceSkew: require("./Website copy.png"),//Skew
    portfolioSkew: require("./Website.png"),//Skew
    contactSkew: require("./Vector 9.png"),
    Artificial_Inteligence: require("./Artificial Inteligence.png"),//Icon
    Devops: require("./devops_icon.png"),//Icon
    appSkew: require("./1.png"),//Appdev Skew Image
    webSkew: require("./Website1.png"),//Webdev Skew Image
    devopSkew: require("./DevOps Solutions.png"),//Devops Skew Image
    popup:require("./portfolio/popup.ffb42f400635a72f42aa.webp"),
    goSportified:require("./portfolio/sportified.2d301011d4f516cb60aa.webp"),
    uniApp:require("./portfolio/uniapp.ffb09d0a9f28513a405f.webp"),
    artur:require("./portfolio/artur.92da54eb5e3371181f7c.webp"),
    karamatSons:require("./portfolio/karmatSons.d8ec0146d6f09a36d980.webp"),
    shopRex:require("./portfolio/shoprex.webp"),
    normadTales:require("./portfolio/normad-tales.da04e09abdff17f08252.webp"),
    natter:require("./portfolio/nephronAI.eda5379645d50d2ea85b.webp"),
    nephroneAI:require("./portfolio/natter.f647ec15ad9c968c855b.webp"),
}

export default images